
#home {
    position: relative;
    background-color: var(--white-color);
    background-size: cover;
    background-repeat: repeat;
    background-position: center;

    .app__wrapper {
        padding: 0;
        
        .copyright {
            display: none;
        }
    }
}

.app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;

    padding: 6rem 2rem 0;

    @media screen and (min-width: 2000px) {
        padding-top: 8rem;
    }

    @media screen and (max-width: 1200px) {
        flex-direction: column;

    }

    @media screen and (max-width: 400px) {
        padding: 6rem 1rem 2rem;
    }
}

.app__header-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    
    @media screen and (max-width: 2000px) {
        width: 100%;
        margin-right: 0rem;
        
    }
}

.app__header-badge {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 2.5rem;

    .head-text{
        color: var(--secondary-color);
        font-size: 2rem;
        text-transform: lowercase;
    }
    .badge-cmp, .tag-cmp {
        padding: 1rem 2rem;
        border: var(--white-color);
        border-radius: 15px;
        flex-direction: row;
        width: auto;
        box-shadow: 0 0 20px rgba(0,0,0,0.3);
        font-weight: 500;

    }
    .badge-cmp {
        p {
            font-size: large;
            font-weight: 500;
        }
        span {
            animation-name: wave-animation;  
            animation-duration: 3.5s;        
            animation-iteration-count: infinite;  

            transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
            display: inline-block;
            @keyframes wave-animation {
                0% { transform: rotate( 0.0deg) scale(2) }
               10% { transform: rotate(14.0deg) scale(2)}  
               20% { transform: rotate(-8.0deg) scale(2)}
               30% { transform: rotate(14.0deg) scale(2)}
               40% { transform: rotate(-4.0deg) scale(2)}
               50% { transform: rotate(10.0deg) scale(2)}
               60% { transform: rotate( 0.0deg) }  
              100% { transform: rotate( 0.0deg) }
            }
        }
    }
    .tag-cmp {
        flex-direction: column;
        margin-top: 3rem;
        
        .p-text {
            visibility: hidden;
            width: 100%;
            text-transform: lowercase;
            text-align: right;
            overflow: hidden; 
            white-space: nowrap; 
            margin: 0 auto; 
            letter-spacing: .02em; 
            animation: 
            borderFade 10s steps(40, end),
            typing 5s steps(40, end),  
            blink-caret .75s step-end infinite,  
            visible 1s infinite;
        }
        .p-text2 {
            visibility: hidden;
            width: 100%;
            text-transform: lowercase;
            text-align: right;
            overflow: hidden; 
            border-right: .15em solid var(--secondary-color); 
            white-space: nowrap; 
            margin: 0 auto; 
            letter-spacing: .02em; 
            animation: 
            typing 5s steps(40, end),
            blink-caret .75s step-end infinite,
            visible 1s infinite;
            animation-delay: 6s;


        }

        /* The typing effect */
        @keyframes typing {
            from { width: 0 }
            to { width: 100% }
        }
        
        /* The typewriter cursor effect */
        @keyframes blink-caret {
            from, to { border-color: transparent }
            50% { border-color: var(--secondary-color); }
        }
        @keyframes visible {
            to {visibility: visible;}
        }
        @keyframes no-border {
            to {border-color: visibility}
        }
    }

    span {
        font-size: 2.5rem;
        @media screen and (min-width: 2000px) {
            font-size: 5rem;
        }
    }

    @media screen and (max-width: 1200px) {
        justify-content: flex-start;
        align-items: flex-start;
    }
}

@keyframes borderFade {
    0% {
        border-right: .15em solid var(--secondary-color);
    }
    100% {
        border-right: .15em solid var(white);
    }
}


@keyframes float {
    0% {
        box-shadow: 0 5px 15px 0px rgba(0,0,0,0.2);
        transform: translatey(0px);
    }
    50% {
        box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
        transform: translatey(-20px);
    }
    100% {
        box-shadow: 0 5px 15px 0px rgba(0,0,0,0.2);
        transform: translatey(0px);
    }
}


.app__header-circles {
    flex: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-left: 1rem;
    



    div {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: var(--white-color);
        box-shadow: 0 0 20px rgba(0,0,0, 0.1);
        

        img{
            width: 60%;
            height: 60%;
            

        }
    }

    div:nth-child(1) {
        width:100px;
        height: 100px;
        animation: float 6s ease-in-out infinite;
    }

    div:nth-child(2) {
        margin: 1.75rem;
        width:150px;
        height: 150px;
        animation: float 4s ease-in-out infinite;
    }

    div:nth-child(3) {
        width:70px;
        height: 70px;
        animation: float 8s ease-in-out infinite;
    }                                                                                                           
   

    @media screen and (min-width: 2000px) {
        div:nth-child(1) {
            width:400px;
            height: 400px;
    
        }

        div:nth-child(2) {
            width:170px;
            height: 170px;
            
        }

        div:nth-child(3) {
            width:200px;
            height: 200px;
            
        }
       
    }

    @media screen and (max-width: 1200px) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 0;
        div{
            margin: 1rem;
        }
    }
}

.app__header-img {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;

    img {
        width:100%;
        object-fit: contain;
        z-index: 1;
        
    }
    .overlay_circle{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        width: 100%;
        height:90%;

    }

    @media screen and (max-width: 1200px) {
        margin: 2rem 0;
        
    }
}